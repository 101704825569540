<template>
  <div class="delivery-record">
    <PageHeader title="出库记录" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex">
        <el-popover
          v-model="visible"
          placement="bottom"
          trigger="manual"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="p-bottom-10 cursor" @click="selectType(1)">按物料查询</div>
            <div class="cursor" @click="selectType(2)">按订单查询</div>
          </div>
          <div slot="reference" class="ModelBttton-white-30 m-right-10" @click="visible = !visible">
            <div>{{ SelectType === 1 ? '按物料查询' : '按订单查询' }}</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
        <el-popover
          v-model="visible1"
          placement="bottom"
          trigger="manual"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="p-bottom-10 cursor" @click="allExport">全部导出</div>
            <div class="cursor" @click="piLiangExport">按已选导出</div>
          </div>
          <div
            v-if=" SelectType === 1"
            slot="reference"
            class="ModelBttton-white-30"
            @click="exportFile"
          >
            <i class="el-icon-upload2 f15 p-right-10" /> 导出
          </div>
        </el-popover>
      </div>
      <div class="out p-lr-10 flex a-center m-lr-10 ModelBttton-white-30" @click="() => $refs.FieldManagement.openSetFieldManagement()">
        <i class="el-icon-setting f14 p-right-5" />   字段管理
      </div>
      <div class="flex m-left-10 SearchBox_30" style="flex: 1">
        <el-input
          v-model="formSearch.KeyWords"
          size="medium"
          :placeholder="SelectType === 1 ? '可通过出库单号、物料号、物料名称、订单号、物料简称、品牌名称、物料注册证号、di码、供应商名称搜索':'可通过出库单号、订单号、交货单号、终端/买方名称搜索'"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer" @click="Adsearch">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <div class="flex m-left-10">
        <div slot="reference" class="ModelBttton-white-30" @click="() => $router.go(-1)">
          <i class="el-icon-back f15" />
          <span>返回</span>
        </div>
      </div>
    </div>
    <div class="p-lr-20">
      <el-table
        ref="testTable"
        v-loading="tableLoading"
        border
        height="calc(100vh - 330px)"
        :data="tableData"
        :header-cell-style="headerCStyle"
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column fixed="left" type="selection" width="40" show-overflow-tooltip />
        <template v-if="formSearch.SelectType === 1">
          <template v-for="(item,index) in tableListJson.deliveryRecord1">
            <el-table-column
              v-if="item.isChecked"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            />
          </template>
        </template>
        <template v-if="formSearch.SelectType === 2">
          <template v-for="(item,index) in tableListJson.deliveryRecord2">
            <el-table-column
              v-if="item.isChecked"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template v-if="item.isChecked && item.prop === 'StockOutId'" slot-scope="scope">
                <el-link v-if="SelectType === 2" type="primary" :underline="false" @click.stop="toSearchOrder(scope.row.StockOutId)">{{ scope.row.StockOutId }}</el-link>
                <span v-else>{{ scope.row.StockOutId }}</span>
              </template>
              <template v-if="item.isChecked && item.prop !== 'StockOutId'" slot-scope="scope">
                {{ scope.row[item.prop] }}
              </template>
            </el-table-column>
          </template>
        </template>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="isShow" @cancel="(e) => isShow = e" @searchAd="searchAd" />
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" :select-type="formSearch.SelectType" :action-name="formSearch.SelectType === 1 ? 'StockOutLogByMaterial' :'StockOutLogByStockOutOrder'" :list="formSearch.SelectType === 1 ?tableListJson.deliveryRecord1 :tableListJson.deliveryRecord2 " :name="formSearch.SelectType === 1 ? 'deliveryRecord1' :'deliveryRecord2'" :change-list-table="changeListTable" />
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
  </div>
</template>
<script>
import Index from '@/minxin/management/delivery-record'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>
